@use '../src/app/commons/styles/colors.scss';
@import '../node_modules/mdb-angular-ui-kit/assets/scss/free/flag';
@import '~@angular/cdk/overlay-prebuilt.css';
@import 'swiper/scss';
@import 'swiper/scss/navigation';
@import 'swiper/scss/pagination';

@font-face {
	font-family:"futura-pt";
	font-display: auto;
	unicodeRange: 'U+000-5FF';
	src:url("assets/fonts/FuturaPTBook.otf") format("opentype");
}

@font-face {
	font-family:"futura-pt-demi";
	font-display: auto;
	unicodeRange: 'U+000-5FF';
	src:url("assets/fonts/FuturaPTDemi.otf") format("opentype");
}

@font-face {
	font-family:"futura-pt-bold";
	font-display: auto;
	unicodeRange: 'U+000-5FF';
	src:url("assets/fonts/FuturaPTBold.otf") format("opentype");
}

@font-face {
	font-family:"futura-pt-medium";
	font-display: auto;
	unicodeRange: 'U+000-5FF';
	src:url("assets/fonts/FuturaPTMedium.otf") format("opentype");
}

.no__scroll {
	overflow: hidden;
}


:root {
	--visibility: hidden;
	--bg-scroll:#7F7F7F;
}

html,
body {
	font-family: "futura-pt";
	height: 100%;
	margin: 0;
	box-sizing:border-box;
}
/*Tabs*/

.show__scroll {
	@media (min-width: 1199px){
		padding-right: 8px !important;	
	}
}

.nav-tabs {
	flex-wrap: nowrap;
	border: none;
	justify-content: center;
	align-items: flex-end;
	gap:16px;
	li {
		width: 100%;
		.nav-link{
			border: none;
			border-radius: 0px;
			padding: 0px;
			padding-bottom: 6px;
			font-size: 18px;
			line-height: 24x;
			@media (max-width: 1199px){
				font-size: 16px;
				line-height: 22px;
			}
		}
		.nav-link.disabled {
			color: colors.$neutral-300;
			border-bottom: 4px solid colors.$neutral-300;
		}
		.nav-link,.nav-link.active {
			color: colors.$neutral-700;
			border-bottom: 4px solid colors.$neutral-700;
		}
	}
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance:textfield;
}

@media (min-width: 1199px){
	.modal-lg {
		--bs-modal-width: 872px;
	}
	.modal-additional-detail {
		--bs-modal-width: 960px;
		--bs-modal-min-width: 960px;
		margin: 0;
	}

	.modal-auth{
		--bs-modal-border-radius: 0px;
		--bs-modal-width: 1096px;
		--bs-modal-min-width: 1096px;
		height:131%;
		margin: 0;
		--bs-modal-border-color:none;
		transform: scale(0.75);
		top: -16%;
	}

	.modal-confirmation {
		--bs-modal-width: 384px;
		--bs-modal-border-radius: 0px;
	}
	.modal-confirm-delete-event {
		--bs-modal-width: 384px;
	}

	.modal-client {
		--bs-modal-width: 640px;
		--bs-modal-border-radius: 0px;
	}
}

@media (max-width: 1198px){
	.modal-lg {
		--bs-modal-width: 640px;
		--bs-modal-border-radius: 0px;
	}

	.modal-auth{
		height:131%;
		transform: scale(0.75);
		top:-20%;
		--bs-modal-border-radius: 0px;
		--bs-modal-border-color:none;
	}

}


@media (max-width: 577px){
	.modal-xl {
		--bs-modal-border-radius: 0px;
		--bs-modal-width: 1096px;
		--bs-modal-border-color:none;

	}
	.modal-lg {
		--bs-modal-margin: 0rem;
		--bs-modal-height:100vh;
		--bs-modal-border-radius: 0px;
		--bs-modal-border-width: 0px;
	}
	.modal-additional-detail {
		--bs-modal-width: 640px;
	}
	.modal-auth{
		--bs-modal-border-radius: 0px;
		--bs-modal-width: 100%;
		--bs-modal-height:100vh;
		--bs-modal-margin: 0px;
		height:100vh;
		transform: scale(1);
		top:0px;
	}

	.modal-confirmation {
		--bs-modal-width: 384px;
		--bs-modal-border-radius: 0px;
	}
	.modal-notification-detail {
		--bs-modal-width: 424px;
	}
	.modal-confirm-delete-event {
		--bs-modal-width: 384px;
	}

	.modal-client {
		--bs-modal-width: 640px;
		--bs-modal-border-radius: 0px;
	}
}


@media (max-width: 200px) {
	.modal-lg {
		--bs-modal-width: 100%;
		--bs-modal-height:100vh;
		--bs-modal-border-radius: 0px;
		--bs-modal-border-width: 0px;
	}
	.modal-additional-detail {
		--bs-modal-width: 100%;
		--bs-modal-height:100vh;
		--bs-modal-border-radius: 0px;
		--bs-modal-border-width: 0px;
	}
	.modal{
		--bs-modal-margin:0px;
	}
	.modal-confirmation {
		--bs-modal-margin: 30px;
		--bs-modal-width: 272px;
		--bs-modal-border-radius: 0px;
	}
	.modal-confirm-delete-event {
		--bs-modal-width: 272px;
	}
	.modal-client {
		--bs-modal-width: 272px;
	}
}



.link {
	text-decoration-line: underline;
	font-weight: 400;
	font-size: 18px;
	line-height: 24px;
	color: colors.$neutral-600;
	cursor: pointer;
	@media (max-width: 1199px){
		font-size: 16px;
		line-height: 22px;
	}
	&:hover{
		color:colors.$blue-300;
	}
}



/********************************Scroll*****************************************/
::-webkit-scrollbar-thumb {
	background: #A3A3A3 !important;
	padding: 0px 4px;
	border-radius: 5px;
}
::-webkit-scrollbar {
	height: 4px;
	width: 8px;
}

::-webkit-scrollbar-track {
	background: #D4D4D4 !important;
}

.grecaptcha-badge {
    visibility: var(--visibility);
}

.day__header__table {
	text-transform: capitalize;
	text-decoration: none;
    background: colors.$neutral-800;
    border: 1px solid colors.$neutral-800;
	*>a{
		text-decoration: none !important;
		font-family: 'futura-pt';
		font-style: normal;
		font-weight: 450;
		font-size: 16px;
		line-height: 24px;
		display: flex;
		align-items: center;
		text-align: center;
		color: #FFFFFF;
	}
}
.fc-day-other {
	background: #F5F5F5;
}

a.fc-daygrid-day-number {
	text-decoration: none;
	color: colors.$neutral-800;
	font-family: 'futura-pt';
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 22px;
}

.fc-day-past, .fc-day-today, .fc-day-future {
	height: 116px;
	min-height: 116px;
	max-height: 116px;
}

//Circle date number
.fc .fc-daygrid-day.fc-day-today {
	background-color: #FFFFFF !important;
	&.selected-cell {
		background-color: colors.$blue-50 !important;
	}
	.fc-daygrid-day-top {
		a.fc-daygrid-day-number{
			text-align: center;
			background: colors.$blue-500;
			border-radius: 100%;
			width: 28px;
			height: 28px;
			font-family: 'futura-pt';
			color: #FFFFFF;
			font-style: normal;
			font-weight: 400;
			font-size: 16px;
			line-height: 22px;
			display: flex;
			justify-content: center;
		}
	}
}

.fc-theme-standard td, .fc-theme-standard th {
    border: 1px solid #D4D4D4 !important;
}

.fc-today-button.fc-button.fc-button-primary {
	display: none;
}

.fc-toolbar-title {
	text-transform: capitalize;
	font-family: 'futura-pt';
	font-style: normal;
	font-weight: 500;
	font-size: 20px;
	line-height: 32px;
	color: colors.$neutral-600;
}

.fc-button-group {
	.fc-button.fc-button-primary {
		background: #FFFFFF;
		border: 0.902868px solid #E5E5E5;
		box-shadow: 0px 0.902868px 1.80574px rgba(16, 24, 40, 0.05);
		color: #737373;
		&:hover {
			background: colors.$neutral-600 !important;
			border: 0.902868px solid none !important;
		}
	}
}

.fc-view-harness.fc-view-harness-active {
	height: 730px !important;
}
.fc-scroller .fc-scroller-liquid-absolute {
	overflow: hidden !important;
}
.fc-col-header {
	width: 100%;
}
.fc-daygrid-body.fc-daygrid-body-balanced, .fc-scrollgrid-sync-table {
	width: 100%;
}
.fc-daygrid-day-top {
	position: absolute;
    bottom: 0;
    right: 0;
}

.number__date {
	width: 28px;
	height: 28px;
	cursor: pointer;
}

//Prueba
.fc-daygrid-day-bottom {
	display: none;
}

.fc-daygrid-event-harness {
	.fc-event.fc-daygrid-event {
		height: 30px;
		color: white;
		background-color: colors.$blue-500;
		span {
			cursor: pointer;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
		padding: 4px 8px;
		gap: 8px;
		@media (max-width: 1199px) {
			font-size: 14px;
			padding: 0;
			color: white;
			height: auto;
		}
		@media (max-width: 575px) {
			min-height: 10px;
			span {
				display: none;
			}
		}
	}
}
.fc-event.fc-h-event {
	@media (max-width: 1199px) {
		width: 100%;
		height: 20px;
	}
}
.fc-daygrid-event-harness-abs {
	.fc-event.fc-event-start.fc-event-end {
		@media (max-width: 1199px) {
			width: 100%;
			border-radius: 100px / 50px;
			margin: 0;
			padding: 0;
		}
	}
}
.fc-daygrid-day-events {
	@media (max-width: 1199px) {
		gap: 6px;
		display: flex;
		flex-direction: column;
	}
}

.selected-cell {
	background-color: colors.$blue-50 !important;
}

//datepicker
.ngb-dp-day.ng-star-inserted{
	.bg-primary {
		--bs-bg-opacity: 1;
		background-color: colors.$blue-500 !important;
		border-radius: 100px !important;
	}
}

.ngb-dp-weekdays {
    border-bottom: 1px solid colors.$neutral-200 !important;
    border-radius: 0;
    background-color: white !important;
	font-style: normal !important;
	font-weight: 400 !important;
	font-size: 18px !important;
}

.ngb-dp-weekday {
	color: colors.$neutral-900 !important;
	font-family: 'futura-pt';
	font-style: normal !important;
}

.ngb-dp-content {
	padding: 5px;
	@media (min-width: 768px) {
		padding: 20px;
	}
}


.ngb-dp-day, .ngb-dp-weekday, .ngb-dp-week-number {
	width: 2.5rem !important;
	height: 2.5rem !important;
}

[ngbDatepickerDayView] {
	width: 2.5rem !important;
	height: 2.5rem !important;
	justify-content: center;
	align-items: center;
	display: flex;
}

.ngb-dp-today {
	color: colors.$red-500;
}

@media (max-width: 1199px) and (min-width: 768px) {
	form.modal-date .dropdown-menu {
		margin-left: 35px !important;
	}
}

.ngb-dp-year .dropdown-menu {
	max-height: 300px;
	overflow-y: auto;
}

form .dropdown-menu {
	.ngb-dp-navigation-select {
		select {
			  margin-top: -1px;
		}
	}
}

@media (max-width: 767px) {
	form.modal-date .dropdown-menu {
		transform: translate3d(0px, 50px, 0px) !important;
		width: 100%;
		.ngb-dp-month {
			width: 100%;
			.ngb-dp-day, .ngb-dp-weekday, .ngb-dp-week-number {
				width: calc(100% / 7) !important;
				height: auto !important;
				aspect-ratio: 1/1;
				align-items: center;
				justify-content: center;
				display: flex;
			}
			[ngbDatepickerDayView] {
				width: 100%;
				height: auto !important;
				aspect-ratio: 1/1;
				justify-content: center;
				align-items: center;
				display: flex;
			}
		}
	}
}

@media (max-width: 767px) and (min-width: 576px) {
	form.profile-date .dropdown-menu {
		left: 35px !important;
	}
}

@media (max-width:371px) {
	form.profile-date .dropdown-menu {
		transform: translate3d(0px, 50px, 0px) !important;
		width: 100%;
		.ngb-dp-month {
			width: 100%;
			.ngb-dp-day, .ngb-dp-weekday, .ngb-dp-week-number {
				width: calc(100% / 7) !important;
				height: auto !important;
				aspect-ratio: 1/1;
				align-items: center;
				justify-content: center;
				display: flex;
			}
			[ngbDatepickerDayView] {
				width: 100%;
				height: auto !important;
				aspect-ratio: 1/1;
				justify-content: center;
				align-items: center;
				display: flex;
			}
		}
	}
}

//Embedded
.embedded_info_block {
    font-family: 'futura-pt';
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    margin: 0;
    text-align: justify;
	h2 {
		font-family: 'futura-pt-bold';
		font-weight: 600;
		font-size: 40px;
		line-height: 52px;
	}
	span, p {
		font-family: 'futura-pt';
		font-weight: 400;
		font-size: 18px;
		line-height: 24px;
		margin: 0;
		text-align: justify;
	}
	a {
		display: flex;
		max-width: 166px;
		margin-top: 56px;
		cursor: pointer;
		color: colors.$neutral-700;
		font-weight: 450;
		font-size: 18px;
		line-height: 24px;
		padding: 12px 0;
		height: 48px;
		text-decoration: none;
		border-bottom: 1.5px solid colors.$neutral-700;
		font-family: 'futura-pt-demi';
		&:hover {
		  border-bottom: 1.75px solid colors.$red-700;
		  }
		&:active {
			color: colors.$red-700;
			border-bottom: 1.75px solid colors.$red-700;
		}
	}
	@media (max-width: 1199px) {
		h2 {
			margin: 0;
			font-weight: 600;
			font-size: 34px;
			line-height: 40px;
		}
		span, p {
			font-weight: 400;
			font-size: 16px;
			line-height: 22px;
		}
		a {
			margin-top: 24px;
			max-width: 146px;
			cursor: pointer;
			color: colors.$neutral-700;
			font-weight: 400;
			font-size: 16px;
			line-height: 22px;
			padding: 12px 0;
			text-decoration: none;
			border-bottom: 1.5px solid colors.$neutral-700;
		}
	}
	@media (max-width: 575px) {
		h2 {
			text-align: center;
		}
		span, p {
			text-align: justify;
		}
		a {
			margin: 24px auto 0 auto;
			max-width: 164px;
			cursor: pointer;
			color: colors.$neutral-700;
			font-weight: 450;
			font-size: 18px;
			line-height: 24px;
			padding: 12px 0;
			text-decoration: none;
			border-bottom: 1.5px solid colors.$neutral-700;
		}
	}
}

.embedded_info_block_link {
	font-family: 'futura-pt';
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    margin: 0;
    text-align: justify;
	margin-top: 56px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	a {
		cursor: pointer;
		color: colors.$neutral-700;
		font-weight: 450;
		font-size: 18px;
		line-height: 24px;
		padding: 12px 0;
		text-decoration: none;
		border-bottom: 1px solid colors.$neutral-700;
		font-family: 'futura-pt-demi';
		&:hover {
			border-bottom: 1.25px solid colors.$red-700;
			}
		&:active {
			color: colors.$red-700;
			border-bottom: 1.25px solid colors.$red-700;
		}
	}

	@media (max-width: 1199px) {
		margin-top: 24px;
		a {
			cursor: pointer;
			color: colors.$neutral-700;
			font-weight: 400;
			font-size: 16px;
			line-height: 22px;
			padding: 12px 0;
			text-decoration: none;
			border-bottom: 1px solid colors.$neutral-700;
		}
	}

	@media (max-width: 575px) {
		margin-top: 32px;
		a {
			cursor: pointer;
			color: colors.$neutral-700;
			font-weight: 450;
			font-size: 18px;
			line-height: 24px;
			padding: 12px 0;
			text-decoration: none;
			border-bottom: 1px solid colors.$neutral-700;
		}
	}
}

.container__investor__info__description {
	.span__link,a{
		color:#171717;
		cursor: pointer;
		text-decoration-line: underline;
		&:hover{
			color:colors.$blue-300;
		}
		&:active{
			color:colors.$blue-400;
		}
	}
}