$primary-color: #F92957;
$secondary-color: #1D1D1B;
$alternative-color:#ec0b43;
$white-color: #fff;
$background-color:#D4D4D4;
$text-color:#737373;
$background-light:#F5F5F5;
$background-card:#FAFAFA;
$border-color:#E5E5E5;
$dark-color:#292D32;

$red:#EF4444;
$red-500:#EB0045;
$red-600:#DC2626; 
$red-700:#FF1A5D;
$red-550:#FF3D76;
$red-400:#F87171;
$red-300:#FCA5A5;
$red-200:#FECACA;
$red-50: #FFE9F0;

$yellow:#FFBF3C;
$yellow-300: #FFBF3C;
$yellow-400:#FFCA5D;
$yellow-500:#F59E0B;
$yellow-600:#FCA900;

$green:#00A887;
$green-400:#00866C;
$green-600:#00866C;
$green-700:#006551;

$blue: #00A0E0;
$blue-40:#F6FAFF;
$blue-50: #E4F4FF;
$blue-300:#40C9FF;
$blue-400:#11BBFF;
$blue-500:#00A0E0;
$blue-600:#0080B3;
$blue-10:#FAFAFA;
$blue-700:#26AEE5;


$white:#fff;

$neutral:#262626;
$neutral-50:#FAFAFA;
$neutral-100:#F5F5F5;
$neutral-450:#737373;
$neutral-200:#E5E5E5;
$neutral-300:#D4D4D4;
$neutral-400:#A3A3A3;
$neutral-600:#525252;
$neutral-700:#404040;
$neutral-800:#262626;
$neutral-900:#171717;
$neutral-bg:#7F7F7F;

$destructive-300:#FCA5A5;
$destructive:#EF4444;
$destructive-200:#FECACA;
$destructive-800:#991B1B;
$destructive-700:#B91C1C;
$destructive-50:#FEF2F2;

$blue-facebook: #3B5998;
$red-google: #DB4437;

$success-200:#BBF7D0;
$success-50:#F0FDF4;
$success-800:#166534;
$success-700:#15803D;
$success:#22C55E; 
